import { forwardRef } from 'react';
import {
  ButtonVariantStates,
  ButtonVariants,
  Button,
  Panel,
  Text,
  FontFamilies,
  FontColors,
} from '@Cortex';

import * as S from './styles';
import ShareIcon from '../../assets/share.svg';
import CalendarIcon from '../../assets/calendar.svg';
import PersonIcon from '../../assets/person.svg';
import { Assets } from '../../../../../utils/assets';

interface Props {
  buttonState: { text: string; state: ButtonVariantStates };
  onButtonClick: () => void;
  referralCount: number;
  rewardsCount: number;
}

export const ReferRewardsDisplay = forwardRef<HTMLDivElement, Props>(
  ({ buttonState, onButtonClick, referralCount, rewardsCount }, ref) => {
    return (
      <S.Container ref={ref}>
        <S.Content>
          <S.Image src={Assets.images.refer.url} />
          <S.BottomPart>
            <S.TitleText>Earn Free Access with Referrals!</S.TitleText>

            <S.SubtitleText>
              Share
              <S.Highlighted> Brain.fm </S.Highlighted>
              and unlock rewards when they subscribe.
            </S.SubtitleText>

            <Panel padding="1.25rem 1.5rem">
              <S.PanelTopRow>
                <S.PanelText family={FontFamilies.SemiBold} size={'1.25rem'}>
                  How It Works
                </S.PanelText>
                <S.PanelText color={FontColors.WhiteTransparent} size={'0.875rem'}>
                  Get <S.Highlighted>1 free month</S.Highlighted> for every successful referral
                  (friends who make a first payment).
                </S.PanelText>
              </S.PanelTopRow>
              <S.PanelSeparator />
              <S.PanelBottomRow>
                <S.PanelIcon src={CalendarIcon} />
                <S.PanelText>Free Months Earned</S.PanelText>
                <Text>{rewardsCount}</Text>
              </S.PanelBottomRow>
              <br />
              <S.PanelBottomRow>
                <S.PanelIcon src={PersonIcon} />
                <S.PanelText>Friends Referred</S.PanelText>
                <Text>{referralCount}</Text>
              </S.PanelBottomRow>
            </Panel>

            <div style={{ flex: 1 }} />

            <S.ButtonWrapper>
              <Button
                data-testid="referLinkCopyButton"
                keepTextCase={true}
                style={{ fontSize: '0.75rem', width: '100%', letterSpacing: '1.2px' }}
                variant={ButtonVariants.Secondary}
                variantState={buttonState.state}
                onClick={onButtonClick}
              >
                <S.ButtonContent>
                  {buttonState.state === ButtonVariantStates.Idle ? (
                    <S.ButtonImage src={ShareIcon} />
                  ) : null}
                  {buttonState.text}
                </S.ButtonContent>
              </Button>
            </S.ButtonWrapper>
          </S.BottomPart>
        </S.Content>
      </S.Container>
    );
  },
);
