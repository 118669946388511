import { useCallback, useEffect } from 'react';
import { Analytics } from '../../../../utils/analytics';
import { CoreAnalyticsEventTypes } from '../../../../domains/Analytics/coreAnalytics.types';
import { useOnboardingIntroCardFeatureFlagEnabled } from '@Utils';
import { IntroCardV1 } from './IntroCardV1';
import { IntroCardV2 } from './IntroCardV2';

type Props = {
  onContinue: () => void;
};

export const IntroCard = ({ onContinue }: Props) => {
  const onboardingIntroCardFeatureFlagEnabled = useOnboardingIntroCardFeatureFlagEnabled();

  useEffect(() => {
    Analytics.logEventWithProperties(CoreAnalyticsEventTypes.OnboardingIntroCardImpression, {
      intro_card_variation: onboardingIntroCardFeatureFlagEnabled ? 'test' : 'control',
    });
  }, []);

  const onClickImage = useCallback(() => {
    Analytics.logEvent('test_onboarding_intro_card_image_click');
  }, []);

  return (
    <>
      {onboardingIntroCardFeatureFlagEnabled ? (
        <IntroCardV2 onClickImage={onClickImage} onContinue={onContinue} />
      ) : (
        <IntroCardV1 onContinue={onContinue} />
      )}
    </>
  );
};
