import styled from 'styled-components';
const IMAGE_WIDTH = 4.5;

export const Container = styled.div`
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  padding: 0.75rem 1rem;
  gap: 1rem;
`;

export const LottieContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${IMAGE_WIDTH}rem;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SoundWave = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: ${IMAGE_WIDTH}rem;
  height: auto;
`;

export const Image = styled.img<{ isClickable?: boolean }>`
  width: ${IMAGE_WIDTH}rem;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
  cursor: ${props => (props.isClickable ? 'pointer' : 'initial')};
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const SubtitleContainer = styled.div``;

export const Subtitle = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 11px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
`;

export const TrackLabel = styled.div`
  font-size: 9px;
  letter-spacing: 1px;
  margin-left: 4px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  padding: 1px 3px;
  position: relative;
  text-transform: uppercase;
`;

export const TitleContainer = styled.div<{ isClickable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TrackActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`;

export const TrackActionButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  height: 24px;
  aspect-ratio: 1/1;
`;

export const NeuralEffectLevelIcon = styled.div`
  display: flex;
  justify-content: center;
`;

export const Activities = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
`;

export const MainActivity = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? 'rgba(255, 255, 255, 0.5)'};
  margin-right: 0.25rem;
`;
