import * as S from './IntroCardV2.styles';
import { Button, ButtonVariants } from '@Cortex';
import { Assets } from '../../../../utils/assets';
import BrandLogo from '../../../../assets/images/brand_logo.svg';

export type Props = {
  onClickImage: () => void;
  onContinue: () => void;
};

export const IntroCardV2 = ({ onContinue, onClickImage }: Props) => {
  return (
    <S.Container>
      <S.Header>
        <S.Logo src={BrandLogo} />
        <S.LogoPlaceholder src={BrandLogo} />
      </S.Header>

      <S.Content>
        <S.HeaderText>Let’s set up your first focus session</S.HeaderText>
        <S.HeaderSubtext>Answer just a few easy questions so we can...</S.HeaderSubtext>

        <S.TipsContainer>
          <S.TipContainer onClick={onClickImage}>
            <S.TipContainerHeader>ACTIVITY</S.TipContainerHeader>
            <S.TipContainerHeaderSubtext>
              Choose tracks purpose-built for your tasks
            </S.TipContainerHeaderSubtext>
            <S.StepImage
              alt={Assets.images.introCard.activities.alt}
              src={Assets.images.introCard.activities.url}
            />
          </S.TipContainer>

          <S.Divider />

          <S.TipContainer onClick={onClickImage}>
            <S.TipContainerHeader>MODULATION STRENGTH</S.TipContainerHeader>
            <S.TipContainerHeaderSubtext>
              Adjust the neuromodulation level for your brain
            </S.TipContainerHeaderSubtext>
            <S.StepImage
              alt={Assets.images.introCard.nel.alt}
              src={Assets.images.introCard.nel.url}
            />
          </S.TipContainer>

          <S.Divider />

          <S.TipContainer onClick={onClickImage}>
            <S.TipContainerHeader>GENRES</S.TipContainerHeader>
            <S.TipContainerHeaderSubtext>Match your favorite genres</S.TipContainerHeaderSubtext>
            <S.StepImage
              alt={Assets.images.introCard.genres.alt}
              src={Assets.images.introCard.genres.url}
            />
          </S.TipContainer>
        </S.TipsContainer>
      </S.Content>
      <S.Buttons>
        <S.ButtonContainer>
          <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={onContinue}>
            Continue
          </Button>
        </S.ButtonContainer>
      </S.Buttons>
    </S.Container>
  );
};
