import { useSelector } from 'react-redux';
import { RootReducerType } from '../../reducers';
import { useIsPomodoroActive } from './useIsPomodoroActive';
import { getTimerMode } from '../../utils/getTimerMode';
import { TimerMode } from '../Session/components/TimerSettings/hooks/useTrackTimerModeChange';

export function useTimerTypeText(): string {
  const { sessionPlayType, timerLengthDisplayValue } = useSelector(
    (state: RootReducerType) => state.sessionManager,
  );
  const isPomodoro = useIsPomodoroActive();
  const timerMode = getTimerMode(sessionPlayType, isPomodoro);

  switch (timerMode) {
    case TimerMode.Infinite:
      return 'Infinite Play';
    case TimerMode.Custom:
      return `${formatUnitForTimerDisplayText(timerLengthDisplayValue)} Timer`;
    case TimerMode.Interval:
      return 'Interval';
    default:
      return 'Infinite Play';
  }
}

const formatUnitForTimerDisplayText = (label?: string) => {
  if (!label) return '';

  const [number, unit] = label.split(' ');

  let formattedUnit;
  if (unit === 'mins' || unit === 'min') {
    formattedUnit = 'Min';
  } else if (unit === 'hrs' || unit === 'hr') {
    formattedUnit = 'Hour';
  } else {
    formattedUnit = unit;
  }

  return `${number || ''} ${formattedUnit || ''}`;
};
