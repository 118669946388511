import { Experiment, ExperimentClient, Variant } from '@amplitude/experiment-js-client';
import Amplitude from 'amplitude-js';
import { AMPLITUDE_KEY } from '../../globals';
import { Logger } from '../../utils/logger';
import { useEffect, useState } from 'react';
import { DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID } from '../Memberships/components/DesktopAnnouncementBanner/constants';
import { OnboardingSpecification } from '../Onboarding/types';

enum AmplitudeExperimentClientStatuses {
  Unititialized = 'Unititialized',
  Initializing = 'Initializing',
  Initialized = 'Initialized',
  Errored = 'Errored',
}

type AmplitudeExperimentClientRef = {
  client: ExperimentClient | null;
  flags: {
    [FIRST_TRACK_TAGS_EXPERIMENT_ID]?: {
      value?: string;
      payload?: {
        body?: string;
      };
    };
    [PAYWALL_VARIATION_EXPERIMENT_ID]?: {
      value?: string;
    };
    [ADHD_SOCIAL_PROOF_ON_PAYWALL_EXPERIMENT_ID]?: {
      value?: string;
    };
    [STREAK_CELEBRATION_MODAL_EXPERIMENT_ID]?: {
      value?: string;
    };
    [COMMITMENT_EXPERIENCE_EXPERIMENT_ID]?: {
      value?: string;
      payload?: {
        options?: { label: string; value: string }[];
      };
    };
    [WEB_DELAYED_PAYWALL_EXPERIMENT_ID]?: {
      value?: string;
      payload?: {
        value?: string;
      };
    };
    [ADHD_MODE_EXPERIMENT_ID]?: {
      value?: string;
    };
    [DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID]?: {
      value?: string;
    };
    [REFERRAL_REWARD_PROGRAM_EXPERIMENT_ID]?: {
      value?: string;
    };
    [PRESET_FOCUS_GENRES_EXPERIMENT_ID]?: {
      value?: string;
    };
    [ONBOARDING_INTRO_CARD_EXPERIMENT_ID]?: {
      value?: string;
    };
  };
  status: AmplitudeExperimentClientStatuses;
};

const AMPLITUDE_EXPERIMENT_CLIENT_REF: AmplitudeExperimentClientRef = {
  client: null,
  flags: {},
  status: AmplitudeExperimentClientStatuses.Unititialized,
};

export function useInitializeAmplitudeExperimentsOnMount(userId?: string): void {
  useEffect(() => {
    if (userId) initializeAmplitudeExperiments();
  }, [userId]);
}

export function clearAmplitudeExperiments(): void {
  if (AMPLITUDE_EXPERIMENT_CLIENT_REF.client) {
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client.clear();
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client.stop();
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client = null;
    AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Unititialized;
  }
}

// EXPERIMENT IDS
const APP_DOWNLOAD_MODAL_EXPERIMENT_ID = 'app-download-onboarding-modal';
const FIRST_TRACK_TAGS_EXPERIMENT_ID = 'first-track-tags';
const SIDE_DECK_OPENED_EXPERIMENT_ID = 'side-deck-opened';
const WEB_MILESTONES_EXPERIMENT_ID = 'web-milestones-rework';
const PAYWALL_VARIATION_EXPERIMENT_ID = 'ab_paywall_variation';
const ONBOARDING_FOCUS_EXPERIMENT_ID = 'web-onboarding-focus';
const ONBOARDING_SLEEP_EXPERIMENT_ID = 'web-onboarding-sleep';
const ONBOARDING_RELAX_EXPERIMENT_ID = 'web-onboarding-relax';
const ONBOARDING_MEDITATE_EXPERIMENT_ID = 'web-onboarding-meditate';
const ONBOARDING_INTRO_CARD_EXPERIMENT_ID = 'web-onboarding-intro-card';
const ADHD_SOCIAL_PROOF_ON_PAYWALL_EXPERIMENT_ID = 'web-adhd-social-proof-on-paywall';
const STREAK_CELEBRATION_MODAL_EXPERIMENT_ID = 'streak-celebration-modal';
const ADHD_MODE_EXPERIMENT_ID = 'web-adhd-toggle';
const COMMITMENT_EXPERIENCE_EXPERIMENT_ID = 'commitment-experience';
const WEB_DELAYED_PAYWALL_EXPERIMENT_ID = 'web_delayer_paywall';
const MONTHLY_DOWNGRADE_EXPERIMENT_ID = 'web-monthly-downgrade-offer';
const REFERRAL_REWARD_PROGRAM_EXPERIMENT_ID = 'web_referral_reward_program';
const PRESET_FOCUS_GENRES_EXPERIMENT_ID = 'web-preset-focus-genres';

export function useWebMilestonesExperiment() {
  const { data } = useAmplitudeExperimentData(WEB_MILESTONES_EXPERIMENT_ID);

  return data
    ? {
        isInTestGroup: data.key !== 'off' && typeof data.value !== 'undefined',
        targetMinutesListened: data.payload?.targetMinutesListened || null,
        ...data,
      }
    : undefined;
}
export function trackWebMilestonesExperimentExposure(): void {
  AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.exposure(WEB_MILESTONES_EXPERIMENT_ID);
}

export function useAppDownloadModalExperiment(): {
  isEnabled: boolean;
} {
  const isEnabled = useBooleanAmplitudeExperiment(APP_DOWNLOAD_MODAL_EXPERIMENT_ID);

  return { isEnabled };
}

export function trackAppDownloadModalExperimentExposure(): void {
  AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.exposure(APP_DOWNLOAD_MODAL_EXPERIMENT_ID);
}

export function useIsDesktopAnnouncementBannerExperimentEnabled(): boolean {
  return AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID]?.value === 'on';
}

export function trackDesktopAnnouncementBannerExperimentExposure(): void {
  AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.exposure(DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID);
}

export function useIsSideDeckOpenedEnabled(): boolean {
  return useBooleanAmplitudeExperiment(SIDE_DECK_OPENED_EXPERIMENT_ID);
}

export function useIsMonthlyDowngradeEnabled(): boolean {
  return useBooleanAmplitudeExperiment(MONTHLY_DOWNGRADE_EXPERIMENT_ID);
}

export function useOnboardingFocusExperimentData(): {
  data: OnboardingSpecification;
  isLoading: boolean;
} {
  return useOnboardingDataAmplitudeExperiment(ONBOARDING_FOCUS_EXPERIMENT_ID);
}

export function useOnboardingSleepExperimentData(): {
  data: OnboardingSpecification;
  isLoading: boolean;
} {
  return useOnboardingDataAmplitudeExperiment(ONBOARDING_SLEEP_EXPERIMENT_ID);
}

export function useOnboardingRelaxExperimentData(): {
  data: OnboardingSpecification;
  isLoading: boolean;
} {
  return useOnboardingDataAmplitudeExperiment(ONBOARDING_RELAX_EXPERIMENT_ID);
}

export function useOnboardingMeditateExperimentData(): {
  data: OnboardingSpecification;
  isLoading: boolean;
} {
  return useOnboardingDataAmplitudeExperiment(ONBOARDING_MEDITATE_EXPERIMENT_ID);
}

export function useADHDExperimentData() {
  return useAmplitudeExperimentData(ADHD_SOCIAL_PROOF_ON_PAYWALL_EXPERIMENT_ID);
}

export function useDelayedPaywallExperiment() {
  const experimentKey =
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[WEB_DELAYED_PAYWALL_EXPERIMENT_ID]?.value;

  const isDelayed = experimentKey?.includes('delayed');

  const delay =
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[WEB_DELAYED_PAYWALL_EXPERIMENT_ID]?.payload?.value;

  return {
    isDelayed,
    delay: delay ? parseInt(delay) : undefined,
  };
}

export function usePresetFocusGenresFeatureFlagEnabled(): boolean {
  return (
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[PRESET_FOCUS_GENRES_EXPERIMENT_ID]?.value ===
    'preset-focus-genres-variant-on'
  );
}

export function useOnboardingIntroCardFeatureFlagEnabled(): boolean {
  return (
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[ONBOARDING_INTRO_CARD_EXPERIMENT_ID]?.value ===
    'onboarding-intro-card-variant-on'
  );
}

export function useReferralRewardProgramFeatureFlagEnabled(): boolean {
  return (
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[REFERRAL_REWARD_PROGRAM_EXPERIMENT_ID]?.value ===
    'referral-reward-program-variant-on'
  );
}

async function initializeAmplitudeExperiments(): Promise<void> {
  if (AMPLITUDE_EXPERIMENT_CLIENT_REF.status !== AmplitudeExperimentClientStatuses.Unititialized) {
    return;
  }

  AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Initializing;

  try {
    const client = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_KEY);
    await client.fetch();
    AMPLITUDE_EXPERIMENT_CLIENT_REF.client = client;
    AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Initialized;

    // set flags in global instance and assign user properties for each one.
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[FIRST_TRACK_TAGS_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(FIRST_TRACK_TAGS_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[PAYWALL_VARIATION_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(PAYWALL_VARIATION_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[ADHD_SOCIAL_PROOF_ON_PAYWALL_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(ADHD_SOCIAL_PROOF_ON_PAYWALL_EXPERIMENT_ID) ||
      {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[COMMITMENT_EXPERIENCE_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(COMMITMENT_EXPERIENCE_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[STREAK_CELEBRATION_MODAL_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(STREAK_CELEBRATION_MODAL_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[ADHD_MODE_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(ADHD_MODE_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[WEB_DELAYED_PAYWALL_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(WEB_DELAYED_PAYWALL_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[REFERRAL_REWARD_PROGRAM_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(REFERRAL_REWARD_PROGRAM_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[PRESET_FOCUS_GENRES_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(PRESET_FOCUS_GENRES_EXPERIMENT_ID) || {};
    AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[ONBOARDING_INTRO_CARD_EXPERIMENT_ID] =
      AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(ONBOARDING_INTRO_CARD_EXPERIMENT_ID) || {};

    Object.entries(AMPLITUDE_EXPERIMENT_CLIENT_REF.flags).forEach(([key, value]) => {
      Amplitude.getInstance().setUserProperties({ [`webFlags__${key}`]: value?.value });
    });

    return;
  } catch (error) {
    AMPLITUDE_EXPERIMENT_CLIENT_REF.status = AmplitudeExperimentClientStatuses.Errored;
    Logger.error('Failed to initialize Amplitude Experiment client', { reason: error });
    return;
  }
}

export function getFirstTrackTagBody(): string {
  return AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[FIRST_TRACK_TAGS_EXPERIMENT_ID]?.payload?.body || '';
}

export function getPaywallVariationFeatureFlag(): 'paywallvariant1b' | 'express_checkout' {
  // fallback to paywallvariant1b
  return (AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[PAYWALL_VARIATION_EXPERIMENT_ID]?.value ||
    'paywallvariant1b') as 'paywallvariant1b';
}

export function getCommitmentExperienceFeatureFlag() {
  return AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[COMMITMENT_EXPERIENCE_EXPERIMENT_ID] || {};
}

export function trackCommitmentExperienceExperimentExposure(): void {
  AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.exposure(COMMITMENT_EXPERIENCE_EXPERIMENT_ID);
}

export function getStreakCelebrationModalFeatureFlag(): 'control' | 'modal' | undefined {
  return AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[STREAK_CELEBRATION_MODAL_EXPERIMENT_ID]?.value as
    | 'control'
    | undefined;
}

export function getAdhdModeFeatureFlag(): 'control' | 'on' | undefined {
  return AMPLITUDE_EXPERIMENT_CLIENT_REF.flags[ADHD_MODE_EXPERIMENT_ID]?.value as
    | 'control'
    | undefined;
}

function useBooleanAmplitudeExperiment(experimentName: string) {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    fetchAmplitudeExperiment();

    async function fetchAmplitudeExperiment() {
      try {
        await AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.fetch();
        const experimentValue: string | undefined =
          AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(experimentName).value;

        setIsEnabled(Boolean(experimentValue && experimentValue !== 'control'));
      } catch (error) {
        Logger.error('Failed to fetch Amplitude Experiments', {
          experimentName,
          reason: error,
        });
      }
    }
  }, []);

  return isEnabled;
}

function useOnboardingDataAmplitudeExperiment(experimentName: string): {
  data: OnboardingSpecification;
  isLoading: boolean;
} {
  const [data, setData] = useState<OnboardingSpecification>({ id: '', cards: [] });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;

    async function fetchAmplitudeExperiment() {
      try {
        await AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.fetch();
        const experimentData = AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(experimentName);

        if (isSubscribed && experimentData?.payload) {
          setData(experimentData.payload);
        }
      } catch (error) {
        Logger.error('Failed to fetch Amplitude Experiments', {
          experimentName,
          reason: error,
        });
      } finally {
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    }

    fetchAmplitudeExperiment();

    return () => {
      isSubscribed = false;
    };
  }, [experimentName]);

  return { data, isLoading };
}

function useAmplitudeExperimentData(experimentName: string) {
  const [data, setData] = useState<Variant | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAmplitudeExperiment();

    async function fetchAmplitudeExperiment() {
      try {
        await Promise.race([
          AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.fetch(),
          resolveInCustomTimeout(500),
        ]);
        const experimentData = AMPLITUDE_EXPERIMENT_CLIENT_REF.client?.variant(experimentName);

        setData(experimentData);
      } catch (error) {
        Logger.error('Failed to fetch Amplitude Experiments', {
          experimentName,
          reason: error,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  return { data, isLoading };
}

export function resolveInCustomTimeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
